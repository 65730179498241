
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ApiCategories } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";

interface Categories {
  id: number;
  name: string;
  code: number;
  status: number;
  category_items: Array<any>;
}
interface props {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "categories-information",
  components: {},
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const props = ref<props>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "category_items",
      lazy: true,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const selectData = ref<Array<Categories>>([]);

    const formData = ref({
      id: route.params.id,
      status: 10,
      name: "",
      code: "",
      parent_id: 0,
      is_public: "",
      category_image: "",
      description: "",
      seo_title: "",
      seo_description: "",
      google_product_category_id: "",
      sort: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Categories Name is required",
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          message: "Categories code is required",
          trigger: "change",
        },
      ],
      parent_id: [
        {
          required: false,
          message: "Parent Category ID is required",
          trigger: "change",
        },
      ],
      is_public: [
        {
          required: true,
          message: "Is Public is required",
          trigger: "change",
        },
      ],
      // sort: [{
      // 	type: 'number',
      // 	message: 'sort must be a number'
      // }, ],
      // google_product_category_id: [{
      // 	type: 'number',
      // 	message: 'google_product_category_id must be a number'
      // }, ]
    });
    const parentChange = (value) => {
      if (value) {
        formData.value.parent_id = value[value.length - 1];
      } else {
        formData.value.parent_id = 0;
      }
    };
    const getSelectList = () => {
      ApiCategories.getCategoriesSelectInfo({
        id: formData.value.id,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value.splice(0, selectData.value.length, ...data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getCategoriesData = () => {
      loading.value = true;
      ApiCategories.getCategoriesInfo({
        id: route.params.id,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            Object.keys(formData.value).forEach((key) => {
              formData.value[key] = data.data[key];
            });
            getSelectList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const deleteCategories = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Categories?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiCategories.deleteCategories({
            id: route.params.id,
          })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Group has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({
                    name: "categories",
                  });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiCategories.updateCategories(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Information", ["Categories"]);
      getCategoriesData();
    });

    return {
      props,
      selectData,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      parentChange,
      submit,
      getCategoriesData,
      deleteCategories,
    };
  },
});
